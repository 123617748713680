.login {
  padding-top: 5%;
}

.challenges-content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.image-segment {
  padding: 0 !important;
  margin: 0 !important;
}

.challenge-image {
  max-height: 250px;
  margin-left: auto;
  margin-right: auto;
}

.delete-icon,
.edit-icon {
  cursor: pointer;
}

img#larry {
  width: 15vw;
}

#day-number {
  position: absolute;
  z-index: 10;
  left: 47%;
  top: 15px;
  font-family: "SixCaps";
  font-size: 7em;
  font-weight: lighter;
  color: red;
}

#circle {
  position: absolute;
  z-index: 9;
  background: white;
  width: 120px;
  height: 120px;
  opacity: 70%;
  left: 33%;
  top: 28px;
  border-radius: 50% !important;
}

#submit-button {
  margin-bottom: 10%;
}
