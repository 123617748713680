h1,
h2,
h3,
h4 {
  font-family: "SixCaps";
}

h1 {
  font-size: 6em;
}

h2 {
  font-size: 5em;
  font-weight: lighter;
}
h3 {
  font-size: 4em;
}
h4 {
  font-size: 2em;
  font-weight: lighter;
}

.divider {
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: dashed 3px;
  color: #b4b2aa;
}

#homelink {
  color: #b4b2aa;
  position: fixed;
  font-family: "SixCaps";
  font-weight: lighter;
  font-size: 5.5vw;
  left: 2px;
  top: 5px;
  padding: 5px;
  cursor: pointer;
}

.challenge {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: "Roboto";
  padding-top: 4%;
}

.challenge h1 {
  font-size: 16vw;
  font-weight: lighter;
  margin-bottom: -30px;
}
.challenge h2 {
  font-size: 9vw;
}

.challenge h3 {
  font-size: 8vw;
  font-weight: lighter;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  padding-top: 15px;
}

.dark {
  height: 30vw;
  padding-top: 10vw;
  margin-bottom: 9vw;
  width: 100%;
  color: #b4b2aa;
  text-align: center;
  font-family: "Roboto";
  font-weight: lighter;
  font-size: 3.3vw;
}

.dark .lock-text {
  position: relative;
  width: 12vw;
  margin-left: auto;
  margin-right: auto;
}

.dark .lock-text h1 {
  font-size: 9vw;
  font-weight: lighter;
}

.dark .lock-text img {
  position: absolute;
  left: 60px;
  top: -15px;
  padding: 0;
  margin: 0;
  width: 40px;
  height: auto;
}

.dark p {
  margin-top: -10px;
}

#challenge-explanation {
  text-align: justify;
}

#extra-content {
  text-align: justify;
}

#extra-content button {
  width: 140px;
  margin-left: auto;
  margin-right: auto;
}

#share-buttons {
  position: fixed;
  width: 40px;
  left: 0;
  bottom: 0;
  margin-left: 3px;
}

#share-buttons .share-button {
  margin: 2px;
}

#share-buttons h4 {
  display: none;
}

.share-button {
  margin: 10px;
}

.clear {
  clear: both;
}

#answer {
  position: relative;
  margin-top: 50px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  font-family: "SixCaps";
  font-size: 20pt;
  letter-spacing: 6px;
}

#answer input {
  width: 200px;
  border: none;
  z-index: 1;
  font-size: 12pt;
  border-radius: 0;
  font-family: "Roboto";
}

#answer input::placeholder {
  color: black;
  font-size: 12pt;
  font-family: "Roboto";
}

#tip {
  cursor: pointer;
  position: absolute;
  left: -55px;
  top: -25px;
  color: white;
  padding: 15px;
  width: 100px;
  /*TODO: comment out height again when 'Tip' is implemented again */
  height: 50px;
  background-color: black;
}

#try {
  cursor: pointer;
  position: absolute;
  left: 205px;
  top: 18px;
  color: white;
  padding: 15px;
  width: 100px;
  background-color: black;
}

#correct {
  background-color: black;
  color: white;
  font-family: "SixCaps";
  font-size: 24pt;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 50px;
}

/* CSS for the final ("end") page */
#end {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#end h1 {
  margin-bottom: 3%;
  font-size: 15vw;
}

#end h2 {
  font-weight: lighter;
  font-size: 10vw;
}

#end img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40vw;
  height: auto;
  margin-bottom: 3%;
}

#end p {
  font-family: "Roboto";
  font-size: 1.5em;
}

@media only screen and (min-width: 768px) {
  #homelink {
    font-size: 5.5vw;
    top: 10px;
  }
  .challenge {
    width: 70%;
  }

  .challenge h1 {
    font-size: 10vw;
  }
  .challenge h2 {
    font-size: 6vw;
  }

  .challenge h3 {
    font-size: 4vw;
  }

  .days p {
    font-size: 2vw;
  }

  img {
    width: 100%;
  }

  img#alternate-img {
    width: 40% !important;
  }

  #answer {
    width: 400px;
  }

  #answer input {
    width: 400px;
  }

  #tip {
    left: -80px;
  }

  #try {
    left: 380px;
  }

  .dark {
    font-size: 3.3vw;
  }

  .dark .lock-text {
    width: 10vw;
  }

  .dark .lock-text h1 {
    font-size: 9vw;
  }

  .dark .lock-text img {
    left: 80px;
    top: -15px;
  }

  /* CSS for the final ("end") page */
  #end h1 {
    font-size: 10vw;
  }

  #end h2 {
    font-weight: lighter;
    font-size: 7vw;
  }

  #end img {
    width: 40vw;
  }
}

@media only screen and (min-width: 992px) {
  #homelink {
    font-size: 5vw;
    top: 15px;
  }

  .challenge {
    width: 60%;
  }

  .challenge h1 {
    font-size: 7vw;
  }

  .challenge h2 {
    font-size: 4vw;
  }

  .challenge h3 {
    font-size: 3vw;
  }

  #end h1 {
    font-size: 8vw;
  }

  #end h2 {
    font-weight: lighter;
    font-size: 6vw;
  }

  #end img {
    width: 20vw;
  }

  .dark {
    font-size: 3.3vw;
  }

  .dark .lock-text {
    width: 12vw;
  }

  .dark .lock-text h1 {
    font-size: 9vw;
  }

  .dark .lock-text img {
    left: 120px;
    top: -15px;
    width: 70px;
  }
}

@media only screen and (min-width: 1300px) {
  #homelink {
    font-size: 4vw;
    top: 15px;
  }

  .challenge {
    width: 50%;
  }

  .challenge h1 {
    font-size: 4.5vw;
  }

  .challenge h2 {
    font-size: 3vw;
  }

  .challenge h3 {
    font-size: 2.5vw;
  }
  #end h1 {
    font-size: 7vw;
  }

  #end h2 {
    font-size: 4vw;
  }

  #end img {
    width: 18vw;
  }

  .dark {
    font-size: 3.3vw;
  }

  .dark .lock-text {
    width: 12vw;
  }

  .dark .lock-text h1 {
    font-size: 9vw;
  }

  .dark .lock-text img {
    left: 140px;
    top: -15px;
    width: 70px;
  }

  #share-buttons {
    text-align: center;
    width: 200px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  #share-buttons .share-button {
    margin: 5px;
  }

  #share-buttons h4 {
    display: block;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1700px) {
  #homelink {
    font-size: 4vw;
    top: 35px;
    left: 5px;
  }

  .dark {
    font-size: 2.5vw;
    height: 25vw;
  }

  .dark .lock-text {
    width: 12vw;
  }

  .dark .lock-text h1 {
    font-size: 7vw;
  }

  .dark .lock-text img {
    left: 200px;
    top: -15px;
    width: 90px;
  }

  #end h1 {
    font-size: 5vw;
  }

  #end h2 {
    font-size: 3vw;
  }

  #end img {
    width: 15vw;
  }
}
