#plan_1,
#plan_2,
#plan_3 {
  display: none;
}

.larry-main {
  padding-top: 3%;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: "Roboto";
}

.images img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.intro-text {
  padding-top: 10px;
  font-size: 4.3vw;
  font-family: "Roboto";
}

#larry {
  width: 60%;
  height: auto;
  margin-top: -5vw;
}

#half-way-banner {
  font-weight: lighter;
  font-family: "SixCaps";
  letter-spacing: 3px;
  margin-top: -10px;
}

#half-way-banner #first {
  font-size: 13.3vw;
}

#half-way-banner #second {
  font-size: 9.8vw;
  margin-top: -25%;
  margin-bottom: 10px;
}

#break-free {
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  padding-top: 8%;
  padding-bottom: 8%;
  background-color: black;
  font-size: 9vw;
  font-weight: 100;
  font-family: "SixCaps";
  color: white;
  letter-spacing: 6px;
  margin-bottom: 100px;
}

#submit {
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  padding-top: 4%;
  padding-bottom: 4%;
  background-color: black;
  font-size: 6vw;
  font-weight: 100;
  font-family: "SixCaps";
  color: white;
  letter-spacing: 6px;
  margin-bottom: 20px;
}

footer {
  font-size: 2.2vw;
}

@media only screen and (min-width: 700px) {
  .larry-main {
    padding-top: 5%;
    width: 50%;
  }

  #half-way-banner #first {
    font-size: 10vw;
  }

  #half-way-banner #second {
    font-size: 7.7vw;
    margin-top: -27%;
  }

  .intro-text {
    font-size: 3.1vw;
  }

  footer {
    font-size: 1.5vw;
  }

  #submit {
    margin-top: 7%;
    font-size: 4.5vw;
    padding-top: 4%;
    padding-bottom: 4%;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1000px) {
  .larry-main {
    padding-top: 1%;
    width: 40%;
  }

  .images img {
    width: 55%;
  }

  #larry {
    width: 40%;
    margin-top: -1.5vw;
  }

  .intro-text {
    font-size: 2.5vw;
  }

  #half-way-banner #first {
    font-size: 8.45vw;
  }

  #half-way-banner #second {
    font-size: 6.45vw;
    margin-top: -27%;
  }

  #break-free {
    font-size: 4vw;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 75%;
  }

  #plan_1,
  #plan_2,
  #plan_3 {
    display: block;
  }
  #plan_1 {
    transform: rotate(270deg);
    position: absolute;
    left: -15vw;
    top: -10vw;
    width: 50%;
    height: auto;
  }

  #plan_2 {
    position: absolute;
    right: -25vw;
    top: 40vw;
    width: 50%;
    height: auto;
  }

  #plan_3 {
    position: absolute;
    left: -26vw;
    top: 90vw;
    width: 50%;
    height: auto;
  }

  #submit {
    margin-top: 5%;
    font-size: 4vw;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 1300px) {
  .larry-main {
    padding-top: 1%;
    width: 30%;
  }

  .images img {
    width: 35%;
  }

  #larry {
    width: 30%;
    margin-top: -1.2vw;
  }

  .intro-text {
    font-size: 1vw;
  }

  #half-way-banner #first {
    font-size: 3.5vw;
  }

  #half-way-banner #second {
    font-size: 2.7vw;
    margin-top: -16%;
  }

  #break-free {
    font-size: 2.2vw;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 55%;
  }

  #plan_1 {
    left: -5vw;
    top: -15vw;
    width: 45%;
    height: auto;
  }

  #plan_2 {
    right: -16vw;
    top: 20vw;
    width: 45%;
    height: auto;
  }

  #plan_3 {
    left: -15vw;
    top: 35vw;
    width: 45%;
    height: auto;
  }

  footer {
    font-size: 0.7vw;
  }

  #submit {
    margin-top: 3%;
    font-size: 3vw;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-bottom: 50px;
  }
}
